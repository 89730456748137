import * as React from "react";
import SettingsMenu from "../../components/SettingMenu";
import SideBarLayout from "../../components/SideBarLayout";
import { useEffect, useState, useRef } from "react";
import config from "../../config";
import firebase from "firebase";
import { navigate } from "gatsby";

const DeleteAccount = () => {
    const [userId, setUserId] = useState(null);
    const [isUserValidated, setIsUserValidated] = useState(false);
    const iframeRef = useRef(null);

    const checkUserExistence = async () => {
        const user = firebase.auth().currentUser;

        if (user) {
            try {
                const response = await fetch(
                    `${config.serverUri}/users?email=${user.email}`
                );
                if (response.ok) {
                    const data = await response.json();
                    if (data.length === 0) {
                        // Si el arreglo es vacío, el usuario ya no existe en la base de datos
                        console.log("El usuario ya no existe, deslogueando...");
                        setTimeout(signOut, 5000); // Espera 5 segundos antes de desloguear
                    } else {
                        setUserId(data[0].id);
                        setIsUserValidated(true); // Usuario validado
                    }
                } else {
                    console.error("Error al obtener el usuario por correo electrónico");
                    signOut(); // Considera desloguear si hay un error en la respuesta del servidor
                }
            } catch (error) {
                console.error("Error en la petición: ", error);
                signOut(); // Considera desloguear si hay un error de petición
            }
        } else {
            // Si no hay un usuario autenticado
            console.log("No hay usuario autenticado, deslogueando...");
            signOut();
        }
    };

    useEffect(() => {
        const interval = setInterval(checkUserExistence, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const handleMessage = (event) => {
          if (event.data === 'cancel') {
            navigate('/');
          }
        };
    
        window.addEventListener('message', handleMessage);
    
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      }, []);

    useEffect(() => {
        if (iframeRef.current) {
          iframeRef.current.onload = () => {
            iframeRef.current.contentWindow.postMessage({ type: 'isWeb', value: true }, '*');
          };
        }
      }, []);

    const signOut = () => {
        firebase.auth().signOut().then(() => {
            navigate("/");
        }).catch(error => {
            console.log(error);
        });
    };

    return (
        <SideBarLayout>
            <SettingsMenu>
                    <iframe
                        ref={iframeRef}
                        src={`https://eliminarcuenta.shiftmexico.com/${userId}`}
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                        title="External Content"
                    ></iframe>
            </SettingsMenu>
        </SideBarLayout>
    );
};

export default DeleteAccount;
